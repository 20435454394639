<script setup>
import Dropdown from '@/Components/Dropdown.vue';
import { onMounted } from 'vue';
import { router } from '@inertiajs/vue3';

import { locale, watchLocale } from '@/Localization.js';
import { isLoaded, loadLanguageAsync, trans, transChoice } from 'laravel-vue-i18n';

const langs = ['fr', 'en', 'es', 'nl'];
const flags = {
    'fr': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAbFBMVEVzldTg4ODS0tLxDwDtAwDjAADD0uz39/fy8vL3k4nzgna4yOixwuXu7u7s6+zn5+fyd2rvcGPtZljYAABrjNCpvOHrWkxegsqfs93NAADpUUFRd8THAABBa7wnVbERRKa8vLyxsLCoqKigoKClCvcsAAAAXklEQVR4AS3JxUEAQQAEwZo13Mk/R9w5/7UERJCIGIgj5qfRJZEpPyNfCgJTjMR1eRRnJiExFJz5Mf1PokWr/UztIjRGQ3V486u0HO55m634U6dMcf0RNPfkVCTvKjO16xHA8miowAAAAABJRU5ErkJggg==',
    'en': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAt1BMVEWSmb66z+18msdig8La3u+tYX9IaLc7W7BagbmcUW+kqMr/q6n+//+hsNv/lIr/jIGMnNLJyOP9/fyQttT/wb3/////aWn+YWF5kNT0oqz0i4ueqtIZNJjhvt/8gn//WVr/6+rN1+o9RKZwgcMPJpX/VFT9UEn+RUX8Ozv2Ly+FGzdYZrfU1e/8LS/lQkG/mbVUX60AE231hHtcdMb0mp3qYFTFwNu3w9prcqSURGNDaaIUMX5FNW5wYt7AAAAAjklEQVR4AR3HNUJEMQCGwf+L8RR36ajR+1+CEuvRdd8kK9MNAiRQNgJmVDAt1yM6kSzYVJUsPNssAk5N7ZFKjVNFAY4co6TAOI+kyQm+LFUEBEKKzuWUNB7rSH/rSnvOulOGk+QlXTBqMIrfYX4tSe2nP3iRa/KNK7uTmWJ5a9+erZ3d+18od4ytiZdvZyuKWy8o3UpTVAAAAABJRU5ErkJggg==',
    'es': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAflBMVEX/AAD9AAD3AADxAADrAAD/eXn9bGz8YWH8WVn6UVH5SEj5Pz/3NDT0Kir9/QD+/nL+/lT18lDt4Uf6+j/39zD39yf19R3n5wDxflXsZ1Pt4Y3x8zr0wbLs1NXz8xPj4wD37t3jmkvsUU/Bz6nrykm3vJ72IiL0FBTyDAvhAABEt4UZAAAAX0lEQVR4AQXBQUrFQBBAwXqTDkYE94Jb73+qfwVRcYxVQRBRToiUfoaVpGTrtdS9SO0Z9FR9lVy/g5c99+dKl30N5uxPuviexXEc9/msC7TOkd4kHu/Dlh4itCJ8AP4B0w4Qwmm7CFQAAAAASUVORK5CYII=',
    'nl': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAY1BMVEX/AAD8AADzAADqAAD/fYz+dYT6aHr7XG36VGb6R1f4L0H/ECz+mKXw8fH9/f36+vr19vbW1tbS0tIAG493n89cjcZNgsA/eLkzcbUpaLAcYKwAAFEAA4ANU6UAADsAAHgAAGcSgiW+AAAAS0lEQVR4AQXBiQ3CQBAAMc/dConQf688SoIdBIIyQiR9R0tCuM2rNxHpMqsDIWiBEE4NgnBiHhVJcs48P0uSjXsPl4hLmH2EHzb4A7DPDGppZMSwAAAAAElFTkSuQmCC'
};

const setLocale = (s) => {
    if (locale.value != s) {
        locale.value = s;
        localStorage.locale = s; // fr|en
        updateLocale(s);
    }
};

const resetLocale = () => {
    if (locale.value) {
        locale.value = null;
        localStorage.removeItem('locale');
        updateLocale(null);
    }
};

const updateLocale = async (lang) => {
    loadLanguageAsync(lang);

    let url = window.location.href.replace(/\/[a-z]{2}\//, `/${lang}/`);

    await watchLocale();

    if (/\/[a-z]{2}\//.test(window.location.pathname)) {
        router.get(url, {},
            {
                preserveState: true,
                preserveScroll: true,
            });
    } else {
        console.error('window.location.reload()', 'updateLocale()', window.location.pathname);
        window.location.reload()
    }
    // TODO? call route('language.switch')
};

const init = () => {
    if (isLoaded()) {
        setLocale(locale.value);
    } else {
        setTimeout(init, 400);
    }
};

onMounted(() => {
    init()
});
</script>

<template>
    <Dropdown align="left" width="32">
        <template #trigger>
            <div class="flex gap-2 items-center cursor-pointer hover:text-blue-600 hover:dark:text-blue-400" :class="{ 'text-blue-600 dark:text-blue-400': !!locale, 'text-gray-800 dark:text-white': !locale }" :title="locale">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                </svg>
                <!-- <img :src="flags[locale]" alt="" width="16" height="11">
                {{ locale }} -->
            </div>
        </template>

        <template #content>
            <button v-for="lang in langs" @click="setLocale(lang)" class="flex gap-2 items-center w-full px-4 py-2 text-left text-sm leading-5 hover:bg-gray-100 dark:hover:bg-[#273E44]/50 focus:outline-none focus:bg-gray-100 dark:focus:bg-[#273E44]/50 transition duration-150 ease-in-out" :class="{ 'text-blue-600 dark:text-blue-400': locale == lang, 'text-gray-700 dark:text-gray-300': locale != lang }">
                <img :src="flags[lang]" alt="" width="16" height="11">
                {{ lang }}
            </button>
            <!-- <button @click="resetLocale()" class="block w-full px-4 py-2 text-left text-sm leading-5 hover:bg-gray-100 dark:hover:bg-[#273E44]/50 focus:outline-none focus:bg-gray-100 dark:focus:bg-[#273E44]/50 transition duration-150 ease-in-out" :class="{ 'text-blue-600 dark:text-blue-400': !locale, 'text-gray-700 dark:text-gray-300': !!locale }">
                System
            </button> -->
        </template>
    </Dropdown>
</template>
<script setup>
import Dropdown from '@/Components/Dropdown.vue';
import { nextTick, onMounted, ref } from 'vue';

import { trans, transChoice } from 'laravel-vue-i18n';

const props = defineProps({
    dropdown_is_visible: { type: Boolean, default: true },
});

const theme = ref(null);
const ico = ref(null);

const getTheme = () => {

    // Cross-domain cookie "dkmode" (0|1)
    // console.error('dkmode', getCookie('dkmode'));

    return localStorage.getItem('theme');
};

const setTheme = (s) => {
    theme.value = s;
    localStorage.theme = s; // light|dark

    // TODO: update cross-domain "dkmode" cookie.
    // ... 

    updateTheme();
};

const resetTheme = () => {
    theme.value = null;
    localStorage.removeItem('theme');
    document.documentElement.classList.remove('dark');

    // TODO: update cross-domain "dkmode" cookie.
    // ... 

    updateTheme();
};

const updateTheme = () => {
    var isSysDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    var currentTheme = theme.value == 'dark' || (!theme.value && isSysDarkTheme);
    ico.value = currentTheme ? 'dark' : 'light';
    if (currentTheme) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }

    watchTheme()
};

const watchTheme = () => {
    let url = route('xhr.theme.switch', { theme: getTheme() });

    axios.get(url)
        .then((response) => {
            // console.error('response', response);
        })
        .catch(error => {
            // If this .catch is commented, axios while throw an exception bc of the abort().
            // console.log('Error', error);
        });
}

const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

onMounted(() => {
    nextTick(() => {
        mediaQueryList.addEventListener('change', updateTheme)
    });

    theme.value = getTheme();
    updateTheme();

    // Force theme to "light" if component is not visible.
    if (!props.dropdown_is_visible) {
        // setTheme('light'); // Xavier a commenté temporairement ce comportement (il a dit wtf faut qu'on voit tout ça meme dans le dark)
        // <Dropdown v-if="dropdown_is_visible" a viré ça aussi en dessous.
    }
});
</script>

<template>
    <Dropdown align="left" width="32">
        <template #trigger>
            <div class="inline cursor-pointer hover:text-blue-600 hover:dark:text-blue-400" :class="{ 'text-blue-600 dark:text-blue-400': !!theme, 'text-gray-800 dark:text-white': !theme }" :title="theme">
                <svg v-show="ico == 'dark'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                </svg>
                <svg v-show="ico == 'light'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                </svg>
            </div>
        </template>

        <template #content>
            <button @click="setTheme('light')" class="block w-full px-4 py-2 text-left text-sm leading-5 hover:bg-gray-100 dark:hover:bg-[#273E44]/50 focus:outline-none focus:bg-gray-100 dark:focus:bg-[#273E44]/50 transition duration-150 ease-in-out" :class="{ 'text-blue-600 dark:text-blue-400': theme == 'light', 'text-gray-700 dark:text-gray-300': theme != 'light' }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 -mt-2 mr-2 inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                </svg>
                {{ trans('Light') }}
            </button>
            <button @click="setTheme('dark')" class="block w-full px-4 py-2 text-left text-sm leading-5 hover:bg-gray-100 dark:hover:bg-[#273E44]/50 focus:outline-none focus:bg-gray-100 dark:focus:bg-[#273E44]/50 transition duration-150 ease-in-out" :class="{ 'text-blue-600 dark:text-blue-400': theme == 'dark', 'text-gray-700 dark:text-gray-300': theme != 'dark' }">
                <svg xmlns="http://www.w3.org/2000/svtext-gray-700 dark:text-gray-300g" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 -mt-2 mr-2 inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                </svg>
                {{ trans('Dark') }}
            </button>
            <button @click="resetTheme()" class="block w-full px-4 py-2 text-left text-sm leading-5 hover:bg-gray-100 dark:hover:bg-[#273E44]/50 focus:outline-none focus:bg-gray-100 dark:focus:bg-[#273E44]/50 transition duration-150 ease-in-out" :class="{ 'text-blue-600 dark:text-blue-400': !theme, 'text-gray-700 dark:text-gray-300': !!theme }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 -mt-2 mr-2 inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                </svg>
                {{ trans('System') }}
            </button>
        </template>
    </Dropdown>
</template>